// ----------------------------------------------------------------------

export function tooltip(theme) {
  const lightMode = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: lightMode ? theme.palette.grey[50] : theme.palette.background.paper,
          color: lightMode ? theme.palette.primary.main : theme.palette.primary.light,
        },
        arrow: {
          color: theme.palette.background.paper,
        },
      },
    },
  };
}
