export const steps = [
  {
    target: '.header', // Element in the Header component
    title: 'Manage Account Settings',
    content:
      'Here, you can manage your account settings such as personal details, password, top-up options, and view your profile.',
    disableBeacon: true,
  },
  {
    target: '.settings', // Element in the NavVertical component
    title: 'Personalize Your Experience',
    content:
      'Customize the app’s theme to match your style preferences and create a more personalized user experience.',
  },
  {
    target: '.appointments', // Element in the NavVertical component
    title: 'Navigate Appointments',
    content:
      'Use this sidebar to book appointments with doctors and review all your scheduled and past appointments.',
  },
  {
    target: '.my-care-page', // Element in the My Care page
    title: 'Manage Your Care',
    content:
      'On the "My Care" page, you can view your initial appointment details, check lab orders and results, prescriptions, and add current conditions or medications.',
  },
  {
    target: '.my-physician', // Element in the My Physician navigation
    title: 'Primary Care Doctor',
    content:
      'This section allows you to view your primary care doctor’s profile, chat with them, and book appointments directly.',
  },
  {
    target: '.find-specialist', // Element in the Find a Specialist navigation
    title: 'Find a Specialist',
    content:
      'Here, you can access a list of specialists organized by categories and easily book appointments with the specialist you need.',
  },
];

export const stepsMini = [
  {
    target: '.header',
    title: 'Manage Account Settings',
    content:
      'Here, you can manage your account settings such as personal details, password, top-up options, and view your profile.',
    disableBeacon: true,
  },
  {
    target: '.settings',
    title: 'Personalize Your Experience',
    content:
      'Customize the app’s theme to match your style preferences and create a more personalized user experience.',
  },
  {
    target: '.side-nav',
    title: 'Navigation Overview',
    content:
      'Use this sidebar to navigate the app. You can book appointments, manage your care by checking lab orders, prescriptions, and adding conditions, access your primary care doctor’s profile and chat, and find specialists in various categories to book appointments.',
  },
];
