import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Joyride from 'react-joyride';
// hooks
import { Button, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { usePathname } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { AuthContext } from 'src/auth/context/jwt';
import customAxios from 'src/utils/customAxios';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { palette as themePalette } from 'src/theme/palette';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import InitialDialog from './component/initial-dialog';
import { steps, stepsMini } from './component/data';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const pathname = usePathname();

  const isAIChat = pathname === '/dashboard/ai-chat';

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const { user } = useAuthContext(AuthContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(true);
  const [tourDialog, setTourDialog] = useState(false);
  const [userData, setUserData] = useState(null);
  const [runTour, setRunTour] = useState(false);
  const [oldUser, setOldUser] = useState(localStorage.getItem('newUser') === 'false');

  const palette = themePalette('light');

  const getUser = async () => {
    try {
      if (user?.userID) {
        const {
          data: { data },
        } = await customAxios.get(`https://abibiman-api.onrender.com/users/${user?.userID}`);

        setUserData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userID]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      userData?.onBoarding === 'personal' &&
      userData?.adminOnBoarding === 'pending' &&
      showDialog &&
      oldUser
    ) {
      const interval = setInterval(() => {
        setDialogOpen(true);
      }, 6000);

      return () => clearInterval(interval);
    }
  }, [userData, showDialog, oldUser]);

  // Tour Dialog
  useEffect(() => {
    if (!oldUser) {
      setTourDialog(true);
    }
  }, [oldUser]);

  const startTour = () => {
    setTourDialog(false);
    setRunTour(true);
    localStorage.setItem('newUser', 'false');
  };

  const skipTour = () => {
    localStorage.setItem('newUser', 'false');
    setOldUser(true);
    setTourDialog(false);
    setRunTour(false);
  };

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>
          {/* Joyride tour */}

          <Joyride
            steps={lgUp ? steps : stepsMini}
            run={runTour}
            continuous
            scrollToFirstStep
            showProgress
            showSkipButton
            callback={(data) => {
              if (data.status === 'finished' || data.status === 'skipped') {
                setRunTour(false);
                localStorage.setItem('newUser', 'false');
                setOldUser(true);
              }
            }}
            styles={{
              options: {
                // arrowColor: '#e3ffeb',
                // backgroundColor: '#e3ffeb',
                overlayColor: '#161c24cc',
                borderRadius: '16px',
                // primaryColor: '#000',
                // textColor: '#004a14',
                // width: 900,
                zIndex: 9999,
              },
              // spotlight: {
              //   backgroundColor: 'transparent',
              // },
              tooltip: {
                borderRadius: 16,
                boxSizing: 'border-box',
                padding: 15,
                position: 'relative',
              },
              tooltipTitle: {
                color: '#212b36',
                fontSize: 18,
                margin: '0 0 10px 0',
                fontWeight: 700,
              },
              tooltipContent: {
                color: '#212b36',
                padding: '20px 10px',
                fontSize: 14,
                fontWeight: 400,
              },
              buttonNext: {
                backgroundColor: palette.primary.main,
                borderRadius: 8,
                color: '#fff',
                fontSize: '0.875rem',
                fontWeight: 700,
                padding: '6px 12px',
                lineHeight: 1.71429,
              },
              buttonBack: {
                backgroundColor: 'transparent',
                border: `1px solid`,
                borderColor: 'rgba(145, 158, 171, 0.32)',
                borderRadius: 8,
                color: '#212b36',
                fontSize: '0.875rem',
                fontWeight: 700,
                padding: '5px 12px',
                lineHeight: 1.71429,
                minWidth: 64,
                marginRight: 12,
              },
            }}
          />

          <Box sx={{ height: '40px', width: '100%', display: isAIChat && 'none' }} />

          <ConfirmDialog
            open={tourDialog}
            onClose={skipTour}
            title="Take a Tour?"
            content="Would you like to take a tour to learn more about how to use the dashboard?"
            action={
              <Button variant="contained" color="primary" onClick={startTour}>
                Yes
              </Button>
            }
          />
          {children}

          <Dialog open={dialogOpen}>
            <InitialDialog setDialogOpen={setDialogOpen} setShowDialog={setShowDialog} />
          </Dialog>
        </Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
